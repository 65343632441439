import {PAYMENT_CONSTANTS} from "../app/talenlio-hireos/home-layout/common/constant/payment-constant";
import {OPEN_AI_CONSTANTS} from "../app/talenlio-hireos/home-layout/common/constant/open-ai";

export const environment = {
    name: 'dev',
    production: false,
    baseApiUrl: 'https://api.dev.talenlio.com/api',
    firebase: {
        apiKey: "AIzaSyB1AI4k7x6JrsDtzYgc1wTgGiomgAYcb_I",
        authDomain: "dev-and-staging-talenlio.firebaseapp.com",
        databaseURL: "https://dev-and-staging-talenlio-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "dev-and-staging-talenlio",
        storageBucket: "dev-and-staging-talenlio.appspot.com",
        messagingSenderId: "703883115589",
        appId: "1:703883115589:web:1ef42e2c010e3d907bf72c",
        measurementId: "G-5QGBL6L1Q9",
        vapidKey: "BGocAB3R5Oon-eVwXt2yH--lQsBG0uBSQ7UJFXyFyC4_kMjG-YTM1nr8hnh9jqj9j72hBDa0-VeqxfKiuJpsYSU"
    },
    edgeFirebase: {
        databaseURL: "https://dev-and-staging-talenlio-edge.europe-west1.firebasedatabase.app/",
    },
    publicApiKey: '10260a23-0241-4171-b52d-6ece53cdff26',
    baseAppUrl: 'https://dev.hireos.talenlio.com',
    hireOsBaseAppUrl: 'https://hireos.dev.talenlio.com',
    standoutBaseAppUrl: 'https://standout.dev.talenlio.com',
    organizaBaseAppUrl: 'https://app.dev.talenlio.com', // NOTE: later we will use 'https://orgniza.dev.talenlio.com',
    showcaseBaseAppUrl: 'https://showcase.dev.talenlio.com',
    payment: PAYMENT_CONSTANTS.STRIPE,
    stripeProperties: {
        publishableKey: PAYMENT_CONSTANTS.PUBLISHABLE_KEY,
        pricingTableId: PAYMENT_CONSTANTS.PRICING_TABLE_ID
    },
    openAiProperties: {
        apiUrl: OPEN_AI_CONSTANTS.API_URL,
        accessToken: OPEN_AI_CONSTANTS.ACCESS_TOKEN,
        model: OPEN_AI_CONSTANTS.MODEL
    },
    signUpBatchId: 'W8wFc31t',
    retoolConfig: {
        dashboard: {
            placementDriveReportsUrl: 'https://ilmtec.retool.com/p/placement-drive-report?_environment=staging&placementDriveId=',
            organizaStatisticsReportUrl: 'https://ilmtec.retool.com/p/organiza-statistics?_environment=staging&universityId='
        }
    },
    linkedInAuthConfig: {
        authUri: `https://www.linkedin.com/oauth/v2/authorization`,
        redirectUri: 'https://hireos.dev.talenlio.com/auth/callback',
        clientId: '77gt2gki02vax0',
        clientSecret: 'St50veE91TJdnd76',
        responseType: 'code',
        scope: 'r_basicprofile openid profile email'
    }
};

